import React, { useState, useEffect, useRef } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  ButtonGroup, 
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography, 
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Spinner,
  IconButton,
  Slider,
  Select, 
  Option,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Tooltip
} from "@material-tailwind/react";

import { v4 as uuidv4 } from 'uuid';
import Inscribe from './Inscribe';

import 'react-toastify/dist/ReactToastify.css';
import '../custom-toast.css';
import Connect from './Connect';
import toast, { Toaster } from 'react-hot-toast';
import checkImg from '../assets/imgs/check.png';
import { useSelector, useDispatch } from 'react-redux';
import { BitcoinNetworkType, signMessage, signTransaction } from 'sats-connect';
import { useWallet, useWallets } from '@wallet-standard/react';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFeeNew, getData, isValidTaprootAddress} from '../util/inscribe-util';
import { getPaymentUtxos, getPaymentHistoryNew, saveTokenTransferMintData, savePaymentHistoryNew } from '../util/api';
import FeeRateCard from './FeeRateCard';
import { tippingAddress, ownerAddress, padding, encodedAddressPrefix, include_mempool} from '../configs/constant';

export default function Indexer() {
  const divRef = useRef(null);

  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});

  const [loading, setLoading] = useState(false);
  const [customFee, setCustomFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);

  const [addresses, setAddresses] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [validDatas, setValidDatas] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [ticker, setTicker] = useState("");
  const [balance, setBalance] = useState(0);
  const [decimal, setDecimal] = useState(8);
  const [show, setShow] = useState(false);
  const [showMint, setShowMint] = useState(false);
  const [inscriptionStatus, setInscriptionStatus] = useState(false);
  const [receiveAddress, setReceiveAddress] = useState("");
  const [mintText, setMintText] = useState("");
  const [feeValues, setFeeValues] = useState({});

  const [textareaText, setTextareaText] = useState("");

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        if (feeRateMode == "Custom") {
          setFeerate(customFee);
          setFeeValues(calculateFeeReal(customFee, 1, mintText.length));
        }
        else
        {
          let response = await getFeeRate();
          setFeeRates(response);
          setFeerate(response[feeRateMode]);
          setFeeValues(calculateFeeReal(response[feeRateMode], 1, mintText.length));
          if (customFee == 0) 
          {
            setCustomFee(response["Fast"] + 1);
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [feeRateMode, customFee])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  const checkValidateAddress = () => {
    // const content = divRef.current.innerHTML;
    // const cleanedContent = content
    //                             .replace(/<div>/g, '@')
    //                             .replace(/<\/div>/g, '@')
    //                             .replace(/<br>/g, '@')
    //                             .replace(/\n/g, '@')
    //                             .replace(/\s+/g, '');
    // let datas = cleanedContent.split("@");

    if (receiveAddress == "") {
      toast.error("Please insert airdrop inscription receiver address!");
      return;
    }

    if (ticker == "") {
      toast.error("Please insert ticker!");
      return;
    }
    if (balance == "" || balance < 0) {
      toast.error("Please insert total amount!");
      return;
    }

    let datas = textareaText.split("\n");

    let temp = [];
    let tempValid = [];
    let total = 0;
    let historyAddresses = [];

    for(let data of datas) {
      let address = "";
      let amount = 1;
      if (data.trim() != "") {
        if (data.includes(",")) {
          let tempArr = data.split(",");
          address = tempArr[0];
          amount = parseInt(tempArr[1]);
        }
        else {
          address = data;
        }
        if (!isValidTaprootAddress(address)){
          temp.push(address);
        } else {
          let flag = 0;
          for(let tep of historyAddresses) {
            if (tep == address.trim()) {
              flag = 1;
              break;
            }
          }
          if (flag == 0) {
            tempValid.push({
              address: address.trim(),
              amount: amount
            })
            total = total + amount;
            historyAddresses.push(address.trim())
          }
        }
      }
    }
    
    setAddresses(temp);
    
    if (tempValid.length > 0) 
      setIsValid(true); 
    else 
      setIsValid(false);

    setValidDatas(tempValid);
    setTotalAmount(total);
    console.log(tempValid);
    console.log(total);

    let res = ""

    let textData = {};
    const textItems = [];
    for(let data of tempValid) {
      let tempAmount = Math.floor(data.amount / total * balance * Math.pow(10, decimal)) / Math.pow(10, decimal);
      res = res + data.address + ", " + tempAmount + '\n';
      textItems.push({
        tick: ticker,
        amt: tempAmount.toString(),
        address : data.address
      })
    }

    textData = {
      p: "tap",
      op: "token-send",
      items: textItems
    }
  
    setMintText(JSON.stringify(textData));
    setTextareaText(res);
  }

  const handleAirdrop = () => {
    if (wallet.nostrPaymentAddress == "") {
      toast.error("Please connect your wallet!");
      return;
    }
    if (!isValid) {
      toast.error("Please validate address first!");
      return;
    }
    else {
      setShow(true);
    }
  }

  const calculateFeeReal = (feeRate, _mintCount, dataSize = contentLength, ) => {
    let mintCount = parseInt(_mintCount);
    if (mintCount <= 0 || mintCount == NaN) mintCount = 1;
    let base_size = 160;
    let prefix = 160;
    let txsize = prefix + Math.floor(dataSize / 4);
    let inscriptionLength = mintCount;
    let inscriptionFee = padding ;
    let networkFee = Math.floor(feeRate * txsize * 1.1);
  
    let royaltyFee = 20000 * mintCount;
    let serviceFee = 0;
    let totalFee = inscriptionFee * mintCount + networkFee * mintCount + serviceFee + royaltyFee;
    return {
      "inscriptionFee": inscriptionFee,
      "networkFee": networkFee,
      "serviceFee": serviceFee,
      "royaltyFee": royaltyFee,
      "totalFee": totalFee
    }
  }

  const handleMint = async () => {

    console.log(mintText);

    setInscriptionStatus(false);
    setShowMint(true);
    setLoading(true);

    const results = await getPaymentHistoryNew();
    const toggleValue = results[0].value;
    let fundingAddress = '';

    if (toggleValue == 0)
      fundingAddress = tippingAddress;
    else
      fundingAddress = ownerAddress;

    let isSuccess = true;

    let inscriptionFee = feeValues["inscriptionFee"] + feeValues["networkFee"];
    let fundingAmount = feeValues["serviceFee"] + feeValues["royaltyFee"];

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());
    console.log(privkey);

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let files = [];

    let mimetype = "text/plain;charset=utf-8";
    let salts = "";

    for(let i = 0; i< 1; i++)
    {
      let text = mintText;
      let textJson = JSON.parse(text);
      files.push({
        text: JSON.stringify(text),
        name: textToHex(text),
        hex: textToHex(text),
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    recipientList.push({
      address: fundingAddress,
      amountSats: BigInt(fundingAmount),
    })

    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    // get payment utxos

    let tempfeerate = feerate;
    if (wallet.domain == "xverseWallet") tempfeerate = Math.ceil(feerate * 1.2);
    const paymentUtxos = await getPaymentUtxos(wallet.nostrPaymentAddress, inscriptionAddressList, inscriptionFee, fundingAddress, fundingAmount, wallet.paymentPublicKey, tempfeerate, 1, wallet.domain)

    if (paymentUtxos.status == "fail") {
      alert("Insufficient balance.");
      setLoading(false);
      setShowMint(false);
      isSuccess = false;
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
              if (isSuccess) {
                // save private key and public key
                await saveTokenTransferMintData({
                  key: privkey,
                  address: receiveAddress,
                  text: mintText
                });
          
                let transactionData;
                while(true)
                {
                  transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
                  if (transactionData.length >= 1){
                    break;
                  }
                  await waitSomeSeconds(2);
                }
          
                for (let i = 0; i < inscriptions.length; i++) {
                  await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
                }
              }
              await savePaymentHistoryNew(1 - toggleValue);
          
              setLoading(false);
              setInscriptionStatus(true);
              return;    
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShowMint(false);
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShowMint(false);
          isSuccess = false;
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
              if (isSuccess) {
                // save private key and public key
                await saveTokenTransferMintData({
                  key: privkey,
                  address: receiveAddress,
                  text: mintText
                });
          
                let transactionData;
                while(true)
                {
                  transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
                  if (transactionData.length >= 1){
                    break;
                  }
                  await waitSomeSeconds(2);
                }
          
                for (let i = 0; i < inscriptions.length; i++) {
                  await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
                }
              }
              await savePaymentHistoryNew(1 - toggleValue);
          
              setLoading(false);
              setInscriptionStatus(true);
              return;    
            },
            onCancel: () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShowMint(false);
          isSuccess = false;
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShowMint(false);
      return;
    }
    
    if (isSuccess) {
          // save private key and public key
      await saveTokenTransferMintData({
        key: privkey,
        address: receiveAddress,
        text: mintText
      });

      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(inscriptions[0].inscriptionAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      for (let i = 0; i < inscriptions.length; i++) {
        await inscribe(inscriptions[i], i, transactionData[0].txid, transactionData[0].value, seckey);
      }
    }
    await savePaymentHistoryNew(1 - toggleValue);

    setLoading(false);
    setInscriptionStatus(true);
  }


  const inscribe = async(inscription, vout, txid2, amt2, seckey) => {
    let _toAddress;
    let _script;
    let toAddress = receiveAddress;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if(_txid2.includes('descendant'))
    {
        include_mempool = false;
        inscribe(inscription, vout, txid2, amt2, seckey);
        return;
    }

    try {

        JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
    }
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <div className="flex flex-col gap-5 md:p-5 p-3 min-h-[100vh] items-center">
      <div className="flex flex-row justify-end w-full">
        <Connect />
      </div>
      <span className="bg-primary text-white sm:text-[32px] text-[28px] py-1 px-4 rounded-md">
        Tap Tokens Airdrop
      </span>
      <div className="flex flex-col gap-5 w-full max-w-[1000px] mt-5">
        <div className="w-full">
          <Input label="Airdrop Inscription Receiver Address" type="text" color="white" value={receiveAddress} onChange={(e) => { setReceiveAddress(e.target.value)}}/>
        </div>
        <div className="w-full">
          <Input label="Ticker" type="text" color="white" value={ticker} onChange={(e) => { setTicker(e.target.value)}}/>
        </div>
        <div className="w-full">
          <Input label="Total Amount" type="text" color="white" value={balance} onChange={(e) => { setBalance(e.target.value)}}/>
        </div>
        <div className="w-full">
          <Input label="Decimal" type="text" color="white" value={decimal} onChange={(e) => { setDecimal(e.target.value)}}/>
        </div>
      </div>
      <div className="relative w-full max-w-[1000px]">
        <div className="text-gray-500 ">Address, Amount (Optional)</div>
        {/* <div 
          ref={divRef}
          className="w-full max-w-[1000px] border-[1px] border-solid border-white text-white h-[500px] rounded-md p-2 overflow-auto" 
          contentEditable= "true">
        </div> */}
        <Textarea className="w-full text-white border-white" rows="20" value={textareaText} onChange={(e) => setTextareaText(e.target.value)}/>
        {
          isValid ?
            <img src = {checkImg} className="w-10 h-10 absolute bottom-2 right-2"/>  : <></>
        }
      </div>
      <div className="flex flex-row gap-4">
        <div className= "cursor-pointer px-3 py-2 rounded-md bg-gray-900 hover:bg-gray-800 text-white" onClick={checkValidateAddress}>REMOVE INVALID</div>
        <div className= "cursor-pointer px-9 py-2 rounded-md bg-primary hover:bg-primary-hover text-white" onClick={handleAirdrop}>AIRDROP</div>
      </div>
      {/* {
        addresses.length > 0 ? 
        <div className="text-red-500 text-[20px] w-full max-w-[1000px]">
          {
            addresses.map((address, index) => {
              return <div key={index}>{address}</div>
            } )
          }
        </div>
        :
        <></>
      } */}
      <Dialog
        open={show}
        className="bg-gray-900 border-gray-600 border-solid border-[1px]"
        size={"xs"}
      >
        <DialogHeader>
          <div className="text-primary font-bold text-[32px] text-center w-full mt-3">
            Airdrop
          </div> 
        </DialogHeader>
        <DialogBody>
          <div className='flex flex-col gap-3 w-full items-center sm:px-4 px-0'>
            <div className="text-left w-full text-gray-600 mt-2 sm:text-[18px] text-[14px]">
              Select the network fee you want to pay:
            </div>
            <div className="grid grid-cols-3 gap-3 w-full">
              <FeeRateCard header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
              <FeeRateCard header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
              <FeeRateCard header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
            </div>
            {
              feeRateMode == "Custom" ? 
              <div className="w-full">
                <Slider color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
              </div>
              : 
              <></>
            }
            <div className="m-auto md:w-[400px] w-[260px]">
              <div className="flex flex-row justify-between mt-1 sm:text-[20px] text-[14px]">
                <div className="font-bold">Total Cost</div>
                <div className="text-right font-bold text-blue-600">{feeValues["totalFee"] / Math.pow(10, 8)} BTC</div>
              </div> 
            </div>
            <div className="flex flex-row w-full justify-center gap-3 ">
              <button className="bg-primary hover:bg-primary-hover rounded-md py-2 sm:min-w-[200px] min-w-[120px] my-3 text-white" onClick={handleMint}>SUBMIT</button>
              <button className="bg-gray-600 rounded-md py-2 sm:min-w-[200px] min-w-[120px] my-3 text-white" onClick={() => setShow(false)}>CLOSE</button>
            </div>
            <div className="mt-3 text-[14px] text-white text-primary">
              Requirement: After the Airdrop inscription is created, you must tap it by sending it to yourself.
            </div>
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={showMint}
        size={"xs"}
      >
        <DialogHeader>
          {
            inscriptionStatus ? 
              <div className="flex flex-row w-full justify-center items-center gap-3 mt-6 sm:text-[32px] text-[24px] font-bold text-primary">
                <CheckCircleIcon strokeWidth={2} className="h-16 w-16" /> <span>Mint successful.</span>
              </div>
              :
              <div className="flex flex-row w-full justify-center mt-6 sm:text-[32px] text-[24px] font-bold text-primary">
                Inscribing Now
              </div>
          }
        </DialogHeader>
        <DialogBody>
          <div className={`flex flex-col gap-3 w-full ${inscriptionStatus ? 'min-h-[100px]' : 'min-h-[160px]'}`}>
            {
              inscriptionStatus ? 
              <div className="flex flex-col items-center gap-4">
                {/* <div className="flex flex-row justify-center w-full text-[20px] px-2 font-semibold text-[#616161] text-center">
                  <CheckCircleIcon strokeWidth={2} className="h-24 w-24" />
                </div> */}
                <div className="w-[180px] mt-5">
                  <Button
                    onClick={() => {
                      setShowMint(false);
                      setInscriptionStatus(false);
                    }}
                    fullWidth
                  >Close</Button>
                </div>
              </div>
              :
              <div className="flex flex-col items-center gap-5">
                <div className="flex flex-row justify-center w-full font-semibold text-[#616161] sm:text-[20px] text-[14px] px-6 text-center">
                  Don´t close this window before the transaction is complete.
                </div>
                <Spinner className="h-12 w-12" />
              </div>
            }
          </div>
        </DialogBody>
      </Dialog>
      <Toaster 
        position="top-right"
        toastOptions={{
          duration: 2000,
          style: {
            background: '#000000',
            color: '#FFFFFF',
            marginTop: '50px'
          },
          iconTheme: {
            primary: '#f9099d',
          }
        }}      
      />
    </div>
  )
}
